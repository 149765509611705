import React from "react";
import { graphql } from "gatsby";
import Seo from "components/seo";
import LetsTalkComponent from "components/letsTalkComponent";
import TopPart from "components/topPart";
import OngoingStory from "components/ongoingStory";
import OurOffice from "components/ourOffice";
import { getAboutPageProps } from "helpers/getAboutPageProps";
import Layout from "components/layout";

const About = ({ data }) => {
  const { seo, topPart, ongoingStory, ourOffice, letsTalk } =
    getAboutPageProps(data);
  return (
    <Layout>
      <Seo {...seo} />
      <TopPart {...topPart} />
      <OngoingStory {...ongoingStory} />
      <OurOffice {...ourOffice} />
      <LetsTalkComponent {...letsTalk} />
    </Layout>
  );
};

export const query = graphql`
  query AboutPageData {
    strapiAboutUs {
      seo {
        metaTitle
        metaDescription
        shareImage {
          alternativeText
          localFile {
            url
          }
        }
      }
      topPart {
        title
        text {
          data {
            text
          }
        }
        slogan
      }
      ongoingStory {
        title
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        storyByYear {
          text
          order
          name
        }
      }
      ourOffice {
        title
        images {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      letsTalk {
        title
        subtitle
        btn
        bgDark
        address
        btnArrow {
          alternativeText
          localFile {
            url
          }
        }
        preezmaLogo {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;

export default About;
