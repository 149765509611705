// extracted by mini-css-extract-plugin
export var activePoint = "ongoingStory-module--activePoint--7d88c";
export var bg = "ongoingStory-module--bg--bd54b";
export var image = "ongoingStory-module--image--f4dfb";
export var line = "ongoingStory-module--line--ceafc";
export var lineActive = "ongoingStory-module--lineActive--061ad";
export var lineContainer = "ongoingStory-module--lineContainer--00480";
export var lineContainerActive = "ongoingStory-module--lineContainerActive--f7dc1";
export var ma0 = "ongoingStory-module--ma-0--6fcbc";
export var ma10 = "ongoingStory-module--ma-10--c8ed3";
export var ma100 = "ongoingStory-module--ma-100--95fc1";
export var ma105 = "ongoingStory-module--ma-105--c2ed9";
export var ma110 = "ongoingStory-module--ma-110--9daf4";
export var ma115 = "ongoingStory-module--ma-115--cbe35";
export var ma12 = "ongoingStory-module--ma-12--8a25d";
export var ma120 = "ongoingStory-module--ma-120--ea622";
export var ma130 = "ongoingStory-module--ma-130--4f9a2";
export var ma140 = "ongoingStory-module--ma-140--fad6a";
export var ma15 = "ongoingStory-module--ma-15--60a28";
export var ma150 = "ongoingStory-module--ma-150--2db2f";
export var ma16 = "ongoingStory-module--ma-16--80220";
export var ma18 = "ongoingStory-module--ma-18--6b180";
export var ma185 = "ongoingStory-module--ma-185--d81cc";
export var ma20 = "ongoingStory-module--ma-20--e5d02";
export var ma25 = "ongoingStory-module--ma-25--77dc8";
export var ma30 = "ongoingStory-module--ma-30--82755";
export var ma35 = "ongoingStory-module--ma-35--77a46";
export var ma40 = "ongoingStory-module--ma-40--4f267";
export var ma45 = "ongoingStory-module--ma-45--ad61a";
export var ma48 = "ongoingStory-module--ma-48--5677b";
export var ma5 = "ongoingStory-module--ma-5--25677";
export var ma50 = "ongoingStory-module--ma-50--68d77";
export var ma55 = "ongoingStory-module--ma-55--1582f";
export var ma60 = "ongoingStory-module--ma-60--ed800";
export var ma70 = "ongoingStory-module--ma-70--26b20";
export var ma80 = "ongoingStory-module--ma-80--b0290";
export var ma85 = "ongoingStory-module--ma-85--8abd9";
export var ma90 = "ongoingStory-module--ma-90--12614";
export var ma95 = "ongoingStory-module--ma-95--bf237";
export var mb0 = "ongoingStory-module--mb-0--4e40e";
export var mb10 = "ongoingStory-module--mb-10--8507c";
export var mb100 = "ongoingStory-module--mb-100--8fd9f";
export var mb105 = "ongoingStory-module--mb-105--d9526";
export var mb110 = "ongoingStory-module--mb-110--60e8e";
export var mb115 = "ongoingStory-module--mb-115--3788f";
export var mb12 = "ongoingStory-module--mb-12--c5c89";
export var mb120 = "ongoingStory-module--mb-120--a7f14";
export var mb130 = "ongoingStory-module--mb-130--0d4d7";
export var mb140 = "ongoingStory-module--mb-140--8fc35";
export var mb15 = "ongoingStory-module--mb-15--82136";
export var mb150 = "ongoingStory-module--mb-150--df051";
export var mb16 = "ongoingStory-module--mb-16--84bd3";
export var mb18 = "ongoingStory-module--mb-18--450f8";
export var mb185 = "ongoingStory-module--mb-185--c6027";
export var mb20 = "ongoingStory-module--mb-20--4b6fa";
export var mb25 = "ongoingStory-module--mb-25--7495e";
export var mb30 = "ongoingStory-module--mb-30--18a4e";
export var mb35 = "ongoingStory-module--mb-35--ae996";
export var mb40 = "ongoingStory-module--mb-40--04ae4";
export var mb45 = "ongoingStory-module--mb-45--6ce3d";
export var mb48 = "ongoingStory-module--mb-48--9b1b3";
export var mb5 = "ongoingStory-module--mb-5--aef1d";
export var mb50 = "ongoingStory-module--mb-50--163dd";
export var mb55 = "ongoingStory-module--mb-55--e8151";
export var mb60 = "ongoingStory-module--mb-60--dba0a";
export var mb70 = "ongoingStory-module--mb-70--8ac5c";
export var mb80 = "ongoingStory-module--mb-80--6c247";
export var mb85 = "ongoingStory-module--mb-85--b6038";
export var mb90 = "ongoingStory-module--mb-90--3dde9";
export var mb95 = "ongoingStory-module--mb-95--81361";
export var mh0 = "ongoingStory-module--mh-0--a7976";
export var mh10 = "ongoingStory-module--mh-10--9a96f";
export var mh100 = "ongoingStory-module--mh-100--18a0a";
export var mh105 = "ongoingStory-module--mh-105--b83ad";
export var mh110 = "ongoingStory-module--mh-110--54206";
export var mh115 = "ongoingStory-module--mh-115--a8399";
export var mh12 = "ongoingStory-module--mh-12--30b73";
export var mh120 = "ongoingStory-module--mh-120--29c05";
export var mh130 = "ongoingStory-module--mh-130--7c844";
export var mh140 = "ongoingStory-module--mh-140--f68ec";
export var mh15 = "ongoingStory-module--mh-15--0c69e";
export var mh150 = "ongoingStory-module--mh-150--b241d";
export var mh16 = "ongoingStory-module--mh-16--15839";
export var mh18 = "ongoingStory-module--mh-18--08105";
export var mh185 = "ongoingStory-module--mh-185--92c79";
export var mh20 = "ongoingStory-module--mh-20--c5985";
export var mh25 = "ongoingStory-module--mh-25--d6836";
export var mh30 = "ongoingStory-module--mh-30--3354f";
export var mh35 = "ongoingStory-module--mh-35--1f9cc";
export var mh40 = "ongoingStory-module--mh-40--a981e";
export var mh45 = "ongoingStory-module--mh-45--404d1";
export var mh48 = "ongoingStory-module--mh-48--354e4";
export var mh5 = "ongoingStory-module--mh-5--51e3c";
export var mh50 = "ongoingStory-module--mh-50--306f7";
export var mh55 = "ongoingStory-module--mh-55--4fc9b";
export var mh60 = "ongoingStory-module--mh-60--6cd3a";
export var mh70 = "ongoingStory-module--mh-70--73d17";
export var mh80 = "ongoingStory-module--mh-80--5634d";
export var mh85 = "ongoingStory-module--mh-85--198ce";
export var mh90 = "ongoingStory-module--mh-90--7778f";
export var mh95 = "ongoingStory-module--mh-95--5577d";
export var ml0 = "ongoingStory-module--ml-0--12500";
export var ml10 = "ongoingStory-module--ml-10--85f5d";
export var ml100 = "ongoingStory-module--ml-100--8ed2b";
export var ml105 = "ongoingStory-module--ml-105--6d815";
export var ml110 = "ongoingStory-module--ml-110--b4428";
export var ml115 = "ongoingStory-module--ml-115--75f8c";
export var ml12 = "ongoingStory-module--ml-12--21020";
export var ml120 = "ongoingStory-module--ml-120--30b02";
export var ml130 = "ongoingStory-module--ml-130--9fcd8";
export var ml140 = "ongoingStory-module--ml-140--9b7c4";
export var ml15 = "ongoingStory-module--ml-15--facd2";
export var ml150 = "ongoingStory-module--ml-150--3ebe5";
export var ml16 = "ongoingStory-module--ml-16--a73ea";
export var ml18 = "ongoingStory-module--ml-18--03777";
export var ml185 = "ongoingStory-module--ml-185--afae2";
export var ml20 = "ongoingStory-module--ml-20--7e882";
export var ml25 = "ongoingStory-module--ml-25--90b8f";
export var ml30 = "ongoingStory-module--ml-30--96a3d";
export var ml35 = "ongoingStory-module--ml-35--f721c";
export var ml40 = "ongoingStory-module--ml-40--50fec";
export var ml45 = "ongoingStory-module--ml-45--f18f6";
export var ml48 = "ongoingStory-module--ml-48--2f8ee";
export var ml5 = "ongoingStory-module--ml-5--f3ebd";
export var ml50 = "ongoingStory-module--ml-50--1cadc";
export var ml55 = "ongoingStory-module--ml-55--ff6a5";
export var ml60 = "ongoingStory-module--ml-60--10071";
export var ml70 = "ongoingStory-module--ml-70--d95a0";
export var ml80 = "ongoingStory-module--ml-80--b220b";
export var ml85 = "ongoingStory-module--ml-85--2fbec";
export var ml90 = "ongoingStory-module--ml-90--c9eb6";
export var ml95 = "ongoingStory-module--ml-95--44b29";
export var mr0 = "ongoingStory-module--mr-0--007f4";
export var mr10 = "ongoingStory-module--mr-10--71df1";
export var mr100 = "ongoingStory-module--mr-100--83482";
export var mr105 = "ongoingStory-module--mr-105--a36ee";
export var mr110 = "ongoingStory-module--mr-110--1a55f";
export var mr115 = "ongoingStory-module--mr-115--bee18";
export var mr12 = "ongoingStory-module--mr-12--9d5cb";
export var mr120 = "ongoingStory-module--mr-120--f659f";
export var mr130 = "ongoingStory-module--mr-130--773a1";
export var mr140 = "ongoingStory-module--mr-140--20dcf";
export var mr15 = "ongoingStory-module--mr-15--318c4";
export var mr150 = "ongoingStory-module--mr-150--f8dcc";
export var mr16 = "ongoingStory-module--mr-16--12e0c";
export var mr18 = "ongoingStory-module--mr-18--f456a";
export var mr185 = "ongoingStory-module--mr-185--c3a09";
export var mr20 = "ongoingStory-module--mr-20--479ad";
export var mr25 = "ongoingStory-module--mr-25--4c083";
export var mr30 = "ongoingStory-module--mr-30--75631";
export var mr35 = "ongoingStory-module--mr-35--28271";
export var mr40 = "ongoingStory-module--mr-40--d4822";
export var mr45 = "ongoingStory-module--mr-45--7879a";
export var mr48 = "ongoingStory-module--mr-48--1a280";
export var mr5 = "ongoingStory-module--mr-5--8feb3";
export var mr50 = "ongoingStory-module--mr-50--4c6ea";
export var mr55 = "ongoingStory-module--mr-55--14778";
export var mr60 = "ongoingStory-module--mr-60--bb195";
export var mr70 = "ongoingStory-module--mr-70--e91c1";
export var mr80 = "ongoingStory-module--mr-80--74e4e";
export var mr85 = "ongoingStory-module--mr-85--cb53c";
export var mr90 = "ongoingStory-module--mr-90--5ab26";
export var mr95 = "ongoingStory-module--mr-95--89530";
export var mt0 = "ongoingStory-module--mt-0--7a21f";
export var mt10 = "ongoingStory-module--mt-10--d6c32";
export var mt100 = "ongoingStory-module--mt-100--996ee";
export var mt105 = "ongoingStory-module--mt-105--2e4dc";
export var mt110 = "ongoingStory-module--mt-110--096fa";
export var mt115 = "ongoingStory-module--mt-115--f051c";
export var mt12 = "ongoingStory-module--mt-12--1cb1a";
export var mt120 = "ongoingStory-module--mt-120--2e232";
export var mt130 = "ongoingStory-module--mt-130--a2668";
export var mt140 = "ongoingStory-module--mt-140--07699";
export var mt15 = "ongoingStory-module--mt-15--66af6";
export var mt150 = "ongoingStory-module--mt-150--4954d";
export var mt16 = "ongoingStory-module--mt-16--129c1";
export var mt18 = "ongoingStory-module--mt-18--0281f";
export var mt185 = "ongoingStory-module--mt-185--8687a";
export var mt20 = "ongoingStory-module--mt-20--e0558";
export var mt25 = "ongoingStory-module--mt-25--b77d0";
export var mt30 = "ongoingStory-module--mt-30--a83be";
export var mt35 = "ongoingStory-module--mt-35--ae0c6";
export var mt40 = "ongoingStory-module--mt-40--baa5f";
export var mt45 = "ongoingStory-module--mt-45--aac6f";
export var mt48 = "ongoingStory-module--mt-48--b8926";
export var mt5 = "ongoingStory-module--mt-5--fc02d";
export var mt50 = "ongoingStory-module--mt-50--f5f82";
export var mt55 = "ongoingStory-module--mt-55--85d1c";
export var mt60 = "ongoingStory-module--mt-60--1ade8";
export var mt70 = "ongoingStory-module--mt-70--80fc1";
export var mt80 = "ongoingStory-module--mt-80--4f795";
export var mt85 = "ongoingStory-module--mt-85--85fea";
export var mt90 = "ongoingStory-module--mt-90--b0331";
export var mt95 = "ongoingStory-module--mt-95--fdf84";
export var mv0 = "ongoingStory-module--mv-0--63de0";
export var mv10 = "ongoingStory-module--mv-10--d614a";
export var mv100 = "ongoingStory-module--mv-100--21678";
export var mv105 = "ongoingStory-module--mv-105--0c59a";
export var mv110 = "ongoingStory-module--mv-110--097d7";
export var mv115 = "ongoingStory-module--mv-115--def45";
export var mv12 = "ongoingStory-module--mv-12--6b95a";
export var mv120 = "ongoingStory-module--mv-120--41745";
export var mv130 = "ongoingStory-module--mv-130--6e954";
export var mv140 = "ongoingStory-module--mv-140--485cc";
export var mv15 = "ongoingStory-module--mv-15--2ce80";
export var mv150 = "ongoingStory-module--mv-150--52bc4";
export var mv16 = "ongoingStory-module--mv-16--33452";
export var mv18 = "ongoingStory-module--mv-18--28578";
export var mv185 = "ongoingStory-module--mv-185--243ca";
export var mv20 = "ongoingStory-module--mv-20--6a6c4";
export var mv25 = "ongoingStory-module--mv-25--78b55";
export var mv30 = "ongoingStory-module--mv-30--cb861";
export var mv35 = "ongoingStory-module--mv-35--d8025";
export var mv40 = "ongoingStory-module--mv-40--2bf24";
export var mv45 = "ongoingStory-module--mv-45--6669c";
export var mv48 = "ongoingStory-module--mv-48--dc523";
export var mv5 = "ongoingStory-module--mv-5--99c25";
export var mv50 = "ongoingStory-module--mv-50--d88fd";
export var mv55 = "ongoingStory-module--mv-55--246f2";
export var mv60 = "ongoingStory-module--mv-60--a9323";
export var mv70 = "ongoingStory-module--mv-70--d8348";
export var mv80 = "ongoingStory-module--mv-80--97e20";
export var mv85 = "ongoingStory-module--mv-85--04ef8";
export var mv90 = "ongoingStory-module--mv-90--025e9";
export var mv95 = "ongoingStory-module--mv-95--e4b22";
export var pa0 = "ongoingStory-module--pa-0--e48d3";
export var pa10 = "ongoingStory-module--pa-10--989bd";
export var pa100 = "ongoingStory-module--pa-100--ffb91";
export var pa105 = "ongoingStory-module--pa-105--7e128";
export var pa110 = "ongoingStory-module--pa-110--75537";
export var pa115 = "ongoingStory-module--pa-115--e470f";
export var pa12 = "ongoingStory-module--pa-12--a369d";
export var pa120 = "ongoingStory-module--pa-120--aeccf";
export var pa130 = "ongoingStory-module--pa-130--eca8c";
export var pa140 = "ongoingStory-module--pa-140--8ea2a";
export var pa15 = "ongoingStory-module--pa-15--29c98";
export var pa150 = "ongoingStory-module--pa-150--724b7";
export var pa16 = "ongoingStory-module--pa-16--fc25d";
export var pa18 = "ongoingStory-module--pa-18--a1960";
export var pa185 = "ongoingStory-module--pa-185--b8711";
export var pa20 = "ongoingStory-module--pa-20--a65a7";
export var pa25 = "ongoingStory-module--pa-25--60b2b";
export var pa30 = "ongoingStory-module--pa-30--5344a";
export var pa35 = "ongoingStory-module--pa-35--1558c";
export var pa40 = "ongoingStory-module--pa-40--c8026";
export var pa45 = "ongoingStory-module--pa-45--6aede";
export var pa48 = "ongoingStory-module--pa-48--47984";
export var pa5 = "ongoingStory-module--pa-5--1464d";
export var pa50 = "ongoingStory-module--pa-50--43b46";
export var pa55 = "ongoingStory-module--pa-55--6f4c7";
export var pa60 = "ongoingStory-module--pa-60--b71d4";
export var pa70 = "ongoingStory-module--pa-70--232cf";
export var pa80 = "ongoingStory-module--pa-80--88ea9";
export var pa85 = "ongoingStory-module--pa-85--1b9e7";
export var pa90 = "ongoingStory-module--pa-90--af518";
export var pa95 = "ongoingStory-module--pa-95--640da";
export var pb0 = "ongoingStory-module--pb-0--8384a";
export var pb10 = "ongoingStory-module--pb-10--4afc0";
export var pb100 = "ongoingStory-module--pb-100--75e23";
export var pb105 = "ongoingStory-module--pb-105--4364f";
export var pb110 = "ongoingStory-module--pb-110--3376b";
export var pb115 = "ongoingStory-module--pb-115--b2958";
export var pb12 = "ongoingStory-module--pb-12--590b6";
export var pb120 = "ongoingStory-module--pb-120--45793";
export var pb130 = "ongoingStory-module--pb-130--b2e25";
export var pb140 = "ongoingStory-module--pb-140--fc688";
export var pb15 = "ongoingStory-module--pb-15--f2784";
export var pb150 = "ongoingStory-module--pb-150--392dd";
export var pb16 = "ongoingStory-module--pb-16--4c3cd";
export var pb18 = "ongoingStory-module--pb-18--3296b";
export var pb185 = "ongoingStory-module--pb-185--1d26f";
export var pb20 = "ongoingStory-module--pb-20--679f1";
export var pb25 = "ongoingStory-module--pb-25--d25ee";
export var pb30 = "ongoingStory-module--pb-30--34b21";
export var pb35 = "ongoingStory-module--pb-35--0a5ed";
export var pb40 = "ongoingStory-module--pb-40--75fb1";
export var pb45 = "ongoingStory-module--pb-45--9c901";
export var pb48 = "ongoingStory-module--pb-48--068ee";
export var pb5 = "ongoingStory-module--pb-5--99616";
export var pb50 = "ongoingStory-module--pb-50--cb433";
export var pb55 = "ongoingStory-module--pb-55--93279";
export var pb60 = "ongoingStory-module--pb-60--7f5a6";
export var pb70 = "ongoingStory-module--pb-70--c8c2e";
export var pb80 = "ongoingStory-module--pb-80--49f6b";
export var pb85 = "ongoingStory-module--pb-85--dd6b1";
export var pb90 = "ongoingStory-module--pb-90--77b9a";
export var pb95 = "ongoingStory-module--pb-95--471ef";
export var ph0 = "ongoingStory-module--ph-0--2d891";
export var ph10 = "ongoingStory-module--ph-10--6be88";
export var ph100 = "ongoingStory-module--ph-100--ce6a3";
export var ph105 = "ongoingStory-module--ph-105--c36e8";
export var ph110 = "ongoingStory-module--ph-110--f6239";
export var ph115 = "ongoingStory-module--ph-115--c24df";
export var ph12 = "ongoingStory-module--ph-12--13125";
export var ph120 = "ongoingStory-module--ph-120--bb9ed";
export var ph130 = "ongoingStory-module--ph-130--b9667";
export var ph140 = "ongoingStory-module--ph-140--7fd59";
export var ph15 = "ongoingStory-module--ph-15--29f29";
export var ph150 = "ongoingStory-module--ph-150--f1dc8";
export var ph16 = "ongoingStory-module--ph-16--9df80";
export var ph18 = "ongoingStory-module--ph-18--a7649";
export var ph185 = "ongoingStory-module--ph-185--bd813";
export var ph20 = "ongoingStory-module--ph-20--525c1";
export var ph25 = "ongoingStory-module--ph-25--4cfa4";
export var ph30 = "ongoingStory-module--ph-30--11c59";
export var ph35 = "ongoingStory-module--ph-35--f0b51";
export var ph40 = "ongoingStory-module--ph-40--58080";
export var ph45 = "ongoingStory-module--ph-45--5d72e";
export var ph48 = "ongoingStory-module--ph-48--7f5ea";
export var ph5 = "ongoingStory-module--ph-5--71f44";
export var ph50 = "ongoingStory-module--ph-50--f3d4a";
export var ph55 = "ongoingStory-module--ph-55--f4423";
export var ph60 = "ongoingStory-module--ph-60--dca06";
export var ph70 = "ongoingStory-module--ph-70--f085e";
export var ph80 = "ongoingStory-module--ph-80--556a0";
export var ph85 = "ongoingStory-module--ph-85--a63fc";
export var ph90 = "ongoingStory-module--ph-90--2ca42";
export var ph95 = "ongoingStory-module--ph-95--b35fe";
export var pl0 = "ongoingStory-module--pl-0--c7486";
export var pl10 = "ongoingStory-module--pl-10--8ed1c";
export var pl100 = "ongoingStory-module--pl-100--e7fde";
export var pl105 = "ongoingStory-module--pl-105--ceb3b";
export var pl110 = "ongoingStory-module--pl-110--1b097";
export var pl115 = "ongoingStory-module--pl-115--997f2";
export var pl12 = "ongoingStory-module--pl-12--96cf3";
export var pl120 = "ongoingStory-module--pl-120--aa55e";
export var pl130 = "ongoingStory-module--pl-130--11db9";
export var pl140 = "ongoingStory-module--pl-140--1d3aa";
export var pl15 = "ongoingStory-module--pl-15--4cfa6";
export var pl150 = "ongoingStory-module--pl-150--05b3f";
export var pl16 = "ongoingStory-module--pl-16--2ac5e";
export var pl18 = "ongoingStory-module--pl-18--28eb5";
export var pl185 = "ongoingStory-module--pl-185--19e64";
export var pl20 = "ongoingStory-module--pl-20--3827f";
export var pl25 = "ongoingStory-module--pl-25--2e0cc";
export var pl30 = "ongoingStory-module--pl-30--84c8a";
export var pl35 = "ongoingStory-module--pl-35--b8305";
export var pl40 = "ongoingStory-module--pl-40--a41e6";
export var pl45 = "ongoingStory-module--pl-45--40402";
export var pl48 = "ongoingStory-module--pl-48--08f1a";
export var pl5 = "ongoingStory-module--pl-5--7f208";
export var pl50 = "ongoingStory-module--pl-50--9643d";
export var pl55 = "ongoingStory-module--pl-55--b472c";
export var pl60 = "ongoingStory-module--pl-60--59dc9";
export var pl70 = "ongoingStory-module--pl-70--34446";
export var pl80 = "ongoingStory-module--pl-80--eec37";
export var pl85 = "ongoingStory-module--pl-85--9bc4a";
export var pl90 = "ongoingStory-module--pl-90--c4c36";
export var pl95 = "ongoingStory-module--pl-95--20992";
export var point = "ongoingStory-module--point--84ca5";
export var pointContainer = "ongoingStory-module--pointContainer--6aa1c";
export var pr0 = "ongoingStory-module--pr-0--a5da4";
export var pr10 = "ongoingStory-module--pr-10--8de7c";
export var pr100 = "ongoingStory-module--pr-100--92eff";
export var pr105 = "ongoingStory-module--pr-105--aa413";
export var pr110 = "ongoingStory-module--pr-110--94ea5";
export var pr115 = "ongoingStory-module--pr-115--82f74";
export var pr12 = "ongoingStory-module--pr-12--92f8f";
export var pr120 = "ongoingStory-module--pr-120--beb03";
export var pr130 = "ongoingStory-module--pr-130--87600";
export var pr140 = "ongoingStory-module--pr-140--c6738";
export var pr15 = "ongoingStory-module--pr-15--68fc3";
export var pr150 = "ongoingStory-module--pr-150--e844f";
export var pr16 = "ongoingStory-module--pr-16--2eb04";
export var pr18 = "ongoingStory-module--pr-18--14214";
export var pr185 = "ongoingStory-module--pr-185--dc41f";
export var pr20 = "ongoingStory-module--pr-20--b0e3f";
export var pr25 = "ongoingStory-module--pr-25--bda6b";
export var pr30 = "ongoingStory-module--pr-30--7702b";
export var pr35 = "ongoingStory-module--pr-35--0d7e6";
export var pr40 = "ongoingStory-module--pr-40--3936f";
export var pr45 = "ongoingStory-module--pr-45--cead4";
export var pr48 = "ongoingStory-module--pr-48--2c079";
export var pr5 = "ongoingStory-module--pr-5--a9ffd";
export var pr50 = "ongoingStory-module--pr-50--f43dd";
export var pr55 = "ongoingStory-module--pr-55--649e1";
export var pr60 = "ongoingStory-module--pr-60--53315";
export var pr70 = "ongoingStory-module--pr-70--fb917";
export var pr80 = "ongoingStory-module--pr-80--30e64";
export var pr85 = "ongoingStory-module--pr-85--8f50a";
export var pr90 = "ongoingStory-module--pr-90--4c547";
export var pr95 = "ongoingStory-module--pr-95--6afbe";
export var pt0 = "ongoingStory-module--pt-0--4e18e";
export var pt10 = "ongoingStory-module--pt-10--beea8";
export var pt100 = "ongoingStory-module--pt-100--eb04b";
export var pt105 = "ongoingStory-module--pt-105--45619";
export var pt110 = "ongoingStory-module--pt-110--8ecc5";
export var pt115 = "ongoingStory-module--pt-115--3443e";
export var pt12 = "ongoingStory-module--pt-12--1a1db";
export var pt120 = "ongoingStory-module--pt-120--48e12";
export var pt130 = "ongoingStory-module--pt-130--6b950";
export var pt140 = "ongoingStory-module--pt-140--f42f0";
export var pt15 = "ongoingStory-module--pt-15--a7a90";
export var pt150 = "ongoingStory-module--pt-150--c35e8";
export var pt16 = "ongoingStory-module--pt-16--4f58b";
export var pt18 = "ongoingStory-module--pt-18--5fdb1";
export var pt185 = "ongoingStory-module--pt-185--1c783";
export var pt20 = "ongoingStory-module--pt-20--9efff";
export var pt25 = "ongoingStory-module--pt-25--3208b";
export var pt30 = "ongoingStory-module--pt-30--daa82";
export var pt35 = "ongoingStory-module--pt-35--61f4c";
export var pt40 = "ongoingStory-module--pt-40--6504d";
export var pt45 = "ongoingStory-module--pt-45--2d0bd";
export var pt48 = "ongoingStory-module--pt-48--1e32a";
export var pt5 = "ongoingStory-module--pt-5--8e61a";
export var pt50 = "ongoingStory-module--pt-50--2dbf6";
export var pt55 = "ongoingStory-module--pt-55--93358";
export var pt60 = "ongoingStory-module--pt-60--a2291";
export var pt70 = "ongoingStory-module--pt-70--6e395";
export var pt80 = "ongoingStory-module--pt-80--66c85";
export var pt85 = "ongoingStory-module--pt-85--76cf5";
export var pt90 = "ongoingStory-module--pt-90--e6866";
export var pt95 = "ongoingStory-module--pt-95--ef49a";
export var pv0 = "ongoingStory-module--pv-0--15e66";
export var pv10 = "ongoingStory-module--pv-10--55c29";
export var pv100 = "ongoingStory-module--pv-100--44a07";
export var pv105 = "ongoingStory-module--pv-105--6ca62";
export var pv110 = "ongoingStory-module--pv-110--af82c";
export var pv115 = "ongoingStory-module--pv-115--4ac74";
export var pv12 = "ongoingStory-module--pv-12--db0a9";
export var pv120 = "ongoingStory-module--pv-120--1adc7";
export var pv130 = "ongoingStory-module--pv-130--0c25d";
export var pv140 = "ongoingStory-module--pv-140--71131";
export var pv15 = "ongoingStory-module--pv-15--b0fb6";
export var pv150 = "ongoingStory-module--pv-150--7c512";
export var pv16 = "ongoingStory-module--pv-16--abaa8";
export var pv18 = "ongoingStory-module--pv-18--ee012";
export var pv185 = "ongoingStory-module--pv-185--2c43b";
export var pv20 = "ongoingStory-module--pv-20--a7032";
export var pv25 = "ongoingStory-module--pv-25--96671";
export var pv30 = "ongoingStory-module--pv-30--a6d12";
export var pv35 = "ongoingStory-module--pv-35--bcfbb";
export var pv40 = "ongoingStory-module--pv-40--9d519";
export var pv45 = "ongoingStory-module--pv-45--5af6f";
export var pv48 = "ongoingStory-module--pv-48--6c98d";
export var pv5 = "ongoingStory-module--pv-5--c1e1b";
export var pv50 = "ongoingStory-module--pv-50--8f3b4";
export var pv55 = "ongoingStory-module--pv-55--52732";
export var pv60 = "ongoingStory-module--pv-60--4d200";
export var pv70 = "ongoingStory-module--pv-70--0b6dc";
export var pv80 = "ongoingStory-module--pv-80--24a53";
export var pv85 = "ongoingStory-module--pv-85--3a6ce";
export var pv90 = "ongoingStory-module--pv-90--cec28";
export var pv95 = "ongoingStory-module--pv-95--7e634";
export var story = "ongoingStory-module--story--eef6d";
export var storyWrapper = "ongoingStory-module--storyWrapper--17851";
export var text = "ongoingStory-module--text--77e34";
export var title = "ongoingStory-module--title--08fa4";
export var wrapper = "ongoingStory-module--wrapper--979be";
export var year = "ongoingStory-module--year--3e770";