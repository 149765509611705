import React from "react";
import * as styles from "./topPart.module.scss";

const TopPart = ({ title, text, slogan }) => {
  return (
    <div className={`${styles.allAbout} pt-70 pb-105`}>
      <p className={`${styles.titleLastPart} mb-50`}>{title}</p>
      <p className={`${styles.text} mb-20`}>{text.data.text}</p>
      <p className={`${styles.text}`}>{slogan}</p>
    </div>
  );
};

export default TopPart;
