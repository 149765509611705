import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { getImgProps } from "helpers/getImgProps";
import * as styles from "./ongoingStory.module.scss";
import { sortArrayOfObjects } from "helpers/sortArrayOfObjects";

const OngoingStory = ({ title, image, storyByYear }) => {
  const storyList = sortArrayOfObjects(
    storyByYear.map(({ name, text }, index) => {
      return (
        <div key={index}>
          <div
            className={`${styles.year} d-flex justify-content-start align-items-center mb-15`}
          >
            <div className={`${styles.pointContainer} mr-55`}>
              <div
                className={
                  index !== storyByYear.length - 1
                    ? `${styles.point}`
                    : `${styles.activePoint}`
                }
              ></div>
            </div>
            <div className={`${styles.year}`}>{name}</div>
          </div>
          <div
            className={`d-flex justify-content-start align-items-start mb-15`}
          >
            <div
              className={
                index !== storyByYear.length - 1
                  ? `${styles.lineContainer} mr-55`
                  : `${styles.lineContainerActive} mr-55`
              }
            >
              <div
                className={
                  index !== storyByYear.length - 1
                    ? styles.line
                    : styles.lineActive
                }
              ></div>
            </div>
            <div className={styles.text}>{text}</div>
          </div>
        </div>
      );
    })
  );
  return (
    <div className={styles.bg}>
      <div className={styles.wrapper}>
        <p className={styles.title}>{title}</p>
        <div
          className={`${styles.storyWrapper} d-flex justify-content-between align-items-start mb-115`}
        >
          <div className={styles.story}>{storyList}</div>
          <div className={styles.image}>
            <GatsbyImage {...getImgProps(image)} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OngoingStory;
