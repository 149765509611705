export const getAboutPageProps = ({
  strapiAboutUs: { seo, topPart, ongoingStory, ourOffice, letsTalk },
}) => {
  return {
    seo,
    topPart,
    ongoingStory,
    ourOffice,
    letsTalk,
  };
};
