import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { getImgProps } from "helpers/getImgProps";
import * as styles from "./ourOffice.module.scss";

const OurOffice = ({ title, images }) => {
  const imageList = images.map((image, i) => {
    return (
      <div key={i} className={styles.img}>
        <GatsbyImage {...getImgProps(image)} />
      </div>
    );
  });
  return (
    <div className={styles.wrapper}>
      <p className={`${styles.title} mb-60`}>{title}</p>
      <div
        className={`${styles.imageContainer} d-flex flex-md-row flex-column-reverse justify-content-md-between align-items-center mv-50`}
      >
        {imageList}
      </div>
    </div>
  );
};

export default OurOffice;
